export const heTranslation = {
  site_name: 'Enigma Hotels',
  transfer: {
    distance: 'מרחק',
  },
  flight: {
    dates: 'תאריכים',
    flight: 'טיסה',
    departure: 'יציאה',
    one_way: 'בכיוון אחד',
    round_trip: 'הלוך ושוב',
    departure_date: 'תאריך יציאה',
    avarage_per_passenger: 'ממוצע לנוסע',
    total_price: 'מחיר סופי',
    date: 'תאריך',
    airport: 'שדה תעופה',
    number: 'מספר טיסה',
    service_time: 'זמן שירות',
    pickup_time: 'זמן איסוף',
    order_summary: 'סיכום הזמנה',
    return_date: 'תאריך חזרה',
  },
  package: {
    general_conditions: 'תנאים כלליים',
  },
};
