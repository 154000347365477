import { createContext } from 'react';
import { Proposal, User } from '@bepro-travel/fe.shared';

export const MainContext = createContext({
  proposal: {} as Proposal,
  setProposal: (_proposal: Proposal) => {},
  mapSupplier: 'google' as 'google' | 'osm',
  user: undefined as User | undefined,
  setUser: (_user?: User) => {},
  setUserSiteConfig: (_config: any) => {},
});
