import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { enTranslation } from '../locales/en';
import { heTranslation } from '../locales/he';
import {
  i18nHotelConfig,
  enHotelTranslation,
  heHotelTranslation,
  itHotelTranslation,
  esHotelTranslation,
  frHotelTranslation,
} from '@bepro-travel/fe.hotel';
import { i18nConfig } from '@bepro-travel/fe.ui';
import {
  enSharedTranslation,
  esSharedTranslation,
  heSharedTranslation,
  itSharedTranslation,
  frSharedTranslation,
} from '@bepro-travel/fe.shared';

import { enTransferTranslation, heTransferTranslation } from '@bepro-travel/fe.transfer';

export const i18nconfig = () => {
  const lang = localStorage.getItem('language') || 'en';

  const en = {
    ...enTranslation,
    ...enHotelTranslation,
    ...enSharedTranslation,
    ...enTransferTranslation,
  };

  const he = {
    ...heTranslation,
    ...heHotelTranslation,
    ...heSharedTranslation,
    ...heTransferTranslation,
  };

  const it = {
    ...itSharedTranslation,
    ...itHotelTranslation,
  };

  const es = {
    ...esSharedTranslation,
    ...esHotelTranslation,
  };

  const fr = {
    ...frSharedTranslation,
    ...frHotelTranslation,
  };

  console.log(fr);

  i18nHotelConfig({
    enTranslation: en,
    heTranslation: he,
    itTranslation: it,
    esTranslation: es,
    frTranslation: fr,
  });
  i18nConfig({
    enTranslation: en,
    heTranslation: he,
    itTranslation: it,
    esTranslation: es,
    frTranslation: fr,
  });

  i18n.use(initReactI18next).init({
    resources: {
      en: {
        translation: en,
      },
      he: {
        translation: he,
      },
      it: {
        translation: it,
      },
      es: {
        translation: es,
      },
      fr: {
        translation: fr,
      },
    },
    lng: lang ? lang : 'en',
    fallbackLng: 'en',

    interpolation: {
      escapeValue: false,
    },
  });
};

export default i18n;
