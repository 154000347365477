import React, { FC, Fragment, useContext, useEffect, useState } from 'react';
import { Link, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { ProposalModal } from '@bepro-travel/fe.shared';
import { MainContext } from '../contexts/MainContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle, faSearch, faUser } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import i18n from '../utils/i18n';
import {
  Accessibility,
  ConfigModal,
  Dropdown,
  ScreenshotSupport,
  SearchHistory,
  auth,
  dateFormat,
} from '@bepro-travel/fe.shared';
import { availableLanguages, cdnUrl } from '../constants/generic';
import ReactCountryFlag from 'react-country-flag';

interface HeaderProps {
  logoUrl: string;
}

const Header: FC<HeaderProps> = ({ logoUrl }) => {
  const [t] = useTranslation();
  const [urlParams] = useSearchParams();
  const [isOpenProposal, setIsOpenProposal] = useState(false);
  const { proposal, setUser, user, setProposal } = useContext(MainContext);
  const [hamburgerState, setHamburgerState] = useState(false);
  const navigate = useNavigate();
  const [isHoverElement, setIsHoverElement] = useState(-2);
  const { pathname } = useLocation();
  const [lang, setLang] = useState(localStorage.getItem('language') || 'en');
  const [proposalLength, setProposalLength] = useState(0);

  useEffect(() => {
    if (proposal) {
      const length =
        proposal.dynamic.length +
        proposal.hotels.length +
        proposal.ilPackages.length +
        proposal.transfers.length +
        proposal.flights.length;
      setProposalLength(length);
    }
    sessionStorage.setItem('proposal', JSON.stringify(proposal));
  }, [proposal]);

  const onClickHotels = (index?: number) => {
    const strIndex = index ? index : '';
    const latestHotelPath = sessionStorage.getItem('latestHotelPath' + strIndex);
    if (latestHotelPath) {
      const loc = JSON.parse(latestHotelPath);
      navigate(loc.pathname + loc.search);
    } else {
      navigate('/hotels' + strIndex);
    }
  };

  const onClickTransfers = () => {
    const latestTransferPath = sessionStorage.getItem('latestTransferPath');
    if (latestTransferPath) {
      const loc = JSON.parse(latestTransferPath);
      navigate(loc.pathname + loc.search);
    } else {
      navigate('/transfers');
    }
  };

  useEffect(() => {
    if (lang === 'he') {
      document.body.classList.add('rtl');
    }
  }, []); // eslint-disable-line

  const onPressHanburger = () => {
    document.querySelector('.header-fields')!.classList.toggle('open');
    setHamburgerState(!hamburgerState);
  };

  const onClickProposal = () => {
    setIsOpenProposal(!isOpenProposal);
    onClickHeaderCell();
  };

  const onClickLogoff = async () => {
    await auth.logout();
    setUser(undefined);
    navigate('/login');
    onClickHeaderCell();
  };

  const onClickHeaderCell = () => {
    document.querySelector('.header-fields')!.classList.remove('open');
    setHamburgerState(false);
  };

  const onChangeLang = (lang: string) => {
    setLang(lang);
    localStorage.setItem('language', lang);

    i18n.changeLanguage(lang);
    if (lang === 'he') {
      document.body.classList.add('rtl');
    } else {
      document.body.classList.remove('rtl');
    }
  };
  const selectedCountry = availableLanguages.find((l) => l.lang === lang)?.country || '';

  return (
    <Fragment>
      <div className="header">
        <Link className="header-logo-wr" to="/" onClick={onClickHeaderCell}>
          <img className="header-logo" src={cdnUrl + logoUrl} alt="Logo" />
        </Link>
        {!!urlParams.get('checkIn') && (
          <div
            className="header-search"
            onClick={() => {
              const element = document.querySelector('.search-wrapper') as HTMLElement;
              element?.classList.toggle('visible-responsive');
            }}
          >
            <FontAwesomeIcon icon={faSearch} className="search-img" />
            <div className="header-search-text">
              <div className="header-search-city">{urlParams.get('city')}</div>
              <div className="header-search-date">
                <div>{dateFormat(urlParams.get('checkIn')!, 'dd MMM')}</div>
                {!!urlParams.get('checkOut') && (
                  <Fragment>
                    <FontAwesomeIcon icon={faCircle} />
                    <div>{dateFormat(urlParams.get('checkOut')!, 'dd MMM')}</div>
                  </Fragment>
                )}
              </div>
            </div>
          </div>
        )}

        <span className="hamburger">
          <input
            id="hamburger__toggle"
            type="checkbox"
            onChange={onPressHanburger}
            checked={hamburgerState}
          />
          <label className="hamburger__btn" htmlFor="hamburger__toggle">
            <span></span>
          </label>
        </span>
        <div className="header-fields">
          <div className="header-oposite"></div>
          {user?.isAuth && (
            <Fragment>
              <SearchHistory />
              <div
                className="header-cell"
                onClick={() => {
                  onClickHeaderCell();
                  onClickTransfers();
                }}
              >
                <span
                  className={'header-cell-text' + (pathname.includes('transfer') ? ' active' : '')}
                >
                  {t('transfer.s')}
                </span>
              </div>
              <div
                className="header-cell"
                onClick={() => {
                  onClickHeaderCell();
                  onClickHotels();
                }}
              >
                <span
                  className={
                    'header-cell-text' +
                    ((pathname.includes('hotel') || pathname.includes('hotels')) &&
                    !pathname.includes('2')
                      ? ' active'
                      : '')
                  }
                >
                  {t('hotel.s') + ' 1'}
                </span>
              </div>
              <div
                className="header-cell"
                onClick={() => {
                  onClickHeaderCell();
                  onClickHotels(2);
                }}
              >
                <span
                  className={
                    'header-cell-text' +
                    (pathname.includes('hotel2') || pathname.includes('hotels2') ? ' active' : '')
                  }
                >
                  {t('hotel.s') + ' 2'}
                </span>
              </div>
              <div className="header-cell" onClick={onClickHeaderCell}>
                <Link
                  to={'group'}
                  className={'header-cell-text' + (pathname === '/group' ? ' active' : '')}
                >
                  {t('group.group')}
                </Link>
              </div>
              <div className="header-cell" onClick={onClickProposal}>
                <span className="header-cell-text">
                  {t('proposal.proposal')}
                  {!!proposalLength && <span className="proposal-header">{proposalLength}</span>}
                </span>
              </div>
              <div className="header-cell" onClick={onClickHeaderCell}>
                <Link
                  to={'my-orders'}
                  className={'header-cell-text' + (pathname === '/my-orders' ? ' active' : '')}
                >
                  {t('mo.mo')}
                </Link>
              </div>
              <div className="header-cell" onClick={onClickLogoff}>
                <span className="header-cell-text">{t('login.off')}</span>
              </div>

              <div className="header-cell mr-5">
                <ConfigModal />
              </div>

              <div className="header-cell mr-5">
                <ScreenshotSupport />
              </div>

              <div className="header-cell mr-5">
                <Dropdown
                  opener={
                    selectedCountry === 'us' ? (
                      <img src={require('../assets/img/us.png')} alt="" />
                    ) : (
                      <ReactCountryFlag svg countryCode={selectedCountry} />
                    )
                  }
                >
                  {availableLanguages.map((lang) => (
                    <div
                      className="c-dropdown-row"
                      key={lang.country}
                      onClick={() => onChangeLang(lang.lang)}
                    >
                      {lang.country === 'us' ? (
                        <img src={require('../assets/img/us.png')} alt="" />
                      ) : (
                        <ReactCountryFlag svg countryCode={lang.country} />
                      )}
                      <span className="ml-5 text-sm">{lang.name}</span>
                    </div>
                  ))}
                </Dropdown>
              </div>

              <div
                className="header-cell header-cell-last"
                onMouseLeave={() => setIsHoverElement(-2)}
                onMouseEnter={() => setIsHoverElement(-1)}
              >
                <FontAwesomeIcon icon={faUser} />

                <div className={'header-dropdown' + (isHoverElement === -1 ? ' open' : '')}>
                  <div className="header-dropdown-row ph-10">
                    <div className="ph-10 mt-10 mb-15 pb-5 bb-1">
                      <span className="text-bold">{t('mo.hello')}, </span>
                      <span className=" text-lg text-contrast">{user?.username}</span>
                    </div>
                    <div className="ph-10 mt-10 mb-15 text-sm">
                      <span className="text-bold">{t('mo.user_code')}</span>: {user?.userCode} -{' '}
                      {user?.username}
                    </div>
                    <div className="ph-10 mt-10 mb-15 text-sm">
                      <span className="text-bold">{t('mo.branch')}</span>: {user?.branchId} -{' '}
                      {user?.branch?.branchTitle}
                    </div>
                    <div className="ph-10 mt-10 mb-15 text-sm">
                      <span className="text-bold">{t('mo.department')}</span>: {user?.departmentId}{' '}
                      - {user?.department?.departmentTitle}
                    </div>
                  </div>
                </div>
              </div>
            </Fragment>
          )}

          {!user?.isAuth && (
            <Fragment>
              <div className="header-cell" onClick={onClickHeaderCell}>
                <Link
                  to={'login'}
                  className={'header-cell-text' + (pathname === '/login' ? ' active' : '')}
                >
                  {t('login.login')}
                </Link>
              </div>
            </Fragment>
          )}
        </div>
      </div>
      {isOpenProposal && (
        <ProposalModal
          onClose={() => setIsOpenProposal(false)}
          proposal={proposal}
          setProposal={setProposal}
        />
      )}
      <Accessibility />
    </Fragment>
  );
};

export default Header;
