import axios, { AxiosError } from 'axios';
import { baseUrl } from '../constants/generic';
import { InitSiteData } from '../ts/interfaces/Generic';

export const configureAxios = () => {
  axios.defaults.baseURL = baseUrl;
  axios.defaults.headers.common['Content-Type'] = 'application/json';
  axios.defaults.headers.common.Accept = 'application/json';

  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error: AxiosError) => {
      if (error.response && error.response.status === 401) {
        window.location.href = '/login';
        localStorage.removeItem('user');
      }
    }
  );
};

export const getRandomImage = async () => {
  try {
    const response = await axios.get('data/RandomImage');
    return response.data;
  } catch (error) {
    return undefined;
  }
};

export const initSite = async (): Promise<InitSiteData | undefined> => {
  const dataStorage = sessionStorage.getItem('initSiteData');
  if (dataStorage) {
    return JSON.parse(dataStorage);
  }

  try {
    const params = window.location.host === 'it.beprotravel.com' ? { lang: 'it' } : {};
    const response = await axios.get('data/init', { params });
    sessionStorage.setItem('initSiteData', JSON.stringify(response.data));

    return response.data;
  } catch (error) {
    return undefined;
  }
};

export const getWorkingHours = async () => {
  try {
    const params = window.location.host === 'it.beprotravel.com' ? { lang: 'it' } : {};

    const response = await axios.get('Account/GetWorkingHours', { params });
    return response.data;
  } catch (error) {
    return undefined;
  }
};
