import React from 'react';
import { SearchTransfer } from '@bepro-travel/fe.transfer';

const MainTransferPage = () => {
  return (
    <div className="display-flex">
      <section className="main-page hotels">
        <SearchTransfer />
      </section>
    </div>
  );
};

export default MainTransferPage;
