export const enTranslation = {
  site_name: 'Enigma Hotels',
  transfer: {
    distance: 'Distance',
  },
  flight: {
    dates: 'Dates',
    flight: 'Flight',
    departure: 'Departure',
    one_way: 'One way',
    round_trip: 'Round trip',
    departure_date: 'Departure date',
    avarage_per_passenger: 'Avarage per passenger',
    total_price: 'Total price',
    date: 'Date',
    airport: 'Airport',
    number: 'Flight number',
    service_time: 'Service time',
    pickup_time: 'Pickup time',
    order_summary: 'Order summary',
    return_date: 'Return date',
  },
  package: {
    general_conditions: 'General Conditions',
    country: 'Country',
  },
};
