import React, { useEffect, useState } from 'react';
import 'react-loading-skeleton/dist/skeleton.css';
import 'react-toastify/dist/ReactToastify.css';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import 'rc-checkbox/assets/index.css';
import '../src/styles/variables.scss';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'react-image-gallery/styles/css/image-gallery.css';

import '@bepro-travel/fe.shared/dist/styles.css';
import '@bepro-travel/fe.hotel/dist/styles.css';
import '@bepro-travel/fe.transfer/dist/styles.css';
import '../src/styles/main.scss';
import '../src/styles/ofakim.scss';
import '../src/styles/enigma.scss';
import '../src/styles/walla.scss';

import { BrowserRouter } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import { configureAxios, initSite } from './services/config';
import { MainContext } from './contexts/MainContext';
import { ToastContainer } from 'react-toastify';
import { i18nconfig } from './utils/i18n';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import {
  hotelConfig,
  mapSupplier,
  getSortHotelOptions,
  cdnUrl,
  hotelConfigImage,
  imgsCar,
} from './constants/generic';
import { HotelConfigContext } from '@bepro-travel/fe.hotel';
import { initCSSVars, initLoader } from './utils/utils';
import { Proposal, ScriptLoader, SharedConfigContext, User, auth } from '@bepro-travel/fe.shared';
import RouterConfig from './components/RouterConfig';
import { TransferConfigContext } from '@bepro-travel/fe.transfer';

function App() {
  const [isAppReady, setIsAppReady] = useState(false);
  const [hotConfig, setHotConfig] = useState<any>(hotelConfig);
  const [user, setUser] = useState<User | undefined>();
  const [recaptchaKey, setRecaptchaKey] = useState('');
  const [isEnigma, setIsEnigma] = useState(false);
  const [logoUrl, setLogoUrl] = useState('');
  const [proposal, setProposal] = useState<Proposal>({
    hotels: [],
    flights: [],
    dynamic: [],
    ilPackages: [],
    transfers: [],
  });

  const [userSiteConfig, setUserSiteConfig] = useState({
    base: {
      openDetailsInNewTab: false,
    },
    hotel: {
      areRoomsGrouped: false,
      areRoomsColoured: false,
      isRoomNameCleared: true,
      areRoomsFiltered: true,
      isCubeDesign: false,
      showRoomsInTab: false,
      isShortMode: false,
      isCubeDesignByEat: false,
      displayMode: 0,
      roomMode: 0,
      oneHotelSearchByOneSupplier: false,
    },
  });

  const mainConfig = {
    proposal,
    setProposal,
    mapSupplier,
    user,
    setUser,
  };

  useEffect(() => {
    (async () => {
      await configureAxios();

      const user = await auth.getUserToken();
      const siteData = await initSite();
      if (siteData) {
        setRecaptchaKey(siteData.recapchaKey);
        initCSSVars(siteData);
        setIsEnigma(siteData.companyId === 135);
        setLogoUrl(siteData.logoUrl);

        if (siteData.other?.siteTitle) {
          document.title = siteData.other.siteTitle + ' - BePro Travel';
        }

        if (siteData.other?.defaultLanguage) {
          const lang = localStorage.getItem('language');
          if (!lang) {
            localStorage.setItem('language', siteData.other.defaultLanguage);
          }
        }
      }

      if (user?.config?.hotel) {
        setUserSiteConfig(user.config);
      }

      const proposal = sessionStorage.getItem('proposal');
      if (proposal) {
        setProposal(JSON.parse(proposal));
      }

      i18nconfig();
      setUser(user);
      initLoader();
      setHotConfig({ ...hotelConfig, user, sortOptions: getSortHotelOptions() });

      setIsAppReady(true);
    })();
  }, []); // eslint-disable-line

  return (
    <BrowserRouter>
      <div className="app">
        {isAppReady && (
          <GoogleReCaptchaProvider reCaptchaKey={recaptchaKey}>
            <ScriptLoader>
              <SharedConfigContext.Provider
                value={{
                  cdnUrl,
                  setUser,
                  user,
                  defaultMultiSearchConfig: '',
                  setHeader: () => {},
                  setProposal,
                  proposal,
                  setFooter: () => {},
                  setSiteConfig: () => {},
                  setLanguage: () => {},
                  language: 'en',
                  SearchBox: () => <></>,
                  imgUs: './assets/img/us.png',
                  imgIs: './assets/img/is.png',
                  imgBg: './assets/img/newbg.jpg',
                  imgLogo: './assets/img/logo.png',
                  history: {
                    visible: false,
                  },
                  userSiteConfig,
                  setUserSiteConfig,
                  b2b: true,
                  hotelConfigImage,
                }}
              >
                <HotelConfigContext.Provider
                  value={{
                    ...hotConfig,
                    user,
                    userSiteConfig,
                    showOrderCompanyId: isEnigma,
                    showOrderTripId: isEnigma,
                  }}
                >
                  <TransferConfigContext.Provider
                    value={{
                      setFooter: () => {},
                      setHeader: () => {},
                      setSiteConfig: () => {},
                      getPage: async () => {},
                      proposal,
                      setProposal,
                      mapSupplier,
                      imgWaypointA: require('./assets/img/waypoint-a.png'),
                      imgWaypointB: require('./assets/img/waypoint-b.png'),
                      imgsCar,
                      cdnUrl,
                      b2b: true,
                    }}
                  >
                    <MainContext.Provider value={{ ...mainConfig, setUserSiteConfig }}>
                      <Header logoUrl={logoUrl} />
                      <RouterConfig isEnigma={isEnigma} />
                      <Footer logoUrl={logoUrl} />
                    </MainContext.Provider>
                  </TransferConfigContext.Provider>
                </HotelConfigContext.Provider>
              </SharedConfigContext.Provider>
            </ScriptLoader>
          </GoogleReCaptchaProvider>
        )}
      </div>
      <ToastContainer />
    </BrowserRouter>
  );
}

export default App;
