import React, { FC, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CredentialsModel } from '../ts/classes/CredentialsModel';
import { Button, FormGroup } from '@bepro-travel/fe.ui';
import { cdnUrl, credentialsValidation as validation } from '../constants/generic';
import { validate } from '../utils/validation';
import { useLocation, useNavigate } from 'react-router-dom';
import { showError, showSuccess } from '@bepro-travel/fe.utils';
import { MainContext } from '../contexts/MainContext';
import { useRecaptcha } from '@bepro-travel/fe.hooks';
import { auth } from '@bepro-travel/fe.shared';
import { getRandomImage } from '../services/config';

interface LoginPageProps {
  isEnigma: boolean;
}

const LoginPage: FC<LoginPageProps> = ({ isEnigma }) => {
  const [t] = useTranslation();
  const navigate = useNavigate();
  const { setUser, setUserSiteConfig } = useContext(MainContext);
  const [form, setForm] = useState(new CredentialsModel());
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { handleRecapcha } = useRecaptcha();
  const { pathname } = useLocation();
  const [bgImage, setBgImage] = useState<{ backgroundImage: string }>();

  useEffect(() => {
    (async () => {
      if (pathname !== '/login') {
        return;
      }

      if (!isEnigma) {
        const response = await getRandomImage();
        setBgImage({ backgroundImage: `url(${cdnUrl + response?.imageUrl})` });
      }
    })();
  }, []); // eslint-disable-line
  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsSubmitted(true);
    const recaptchaToken = await handleRecapcha();
    if (!recaptchaToken) return;

    if (validate(validation, form)) {
      setIsLoading(true);

      const user = await auth.login({ ...form, recaptchaToken });

      if (user) {
        navigate('/');
        showSuccess(t('login.success'));
        setUser(user);

        if (user.config?.hotel) {
          setUserSiteConfig(user.config);
        }
      } else {
        showError(t('login.error'));
      }

      setIsLoading(false);
    }
  };

  return (
    <div
      className="login-top"
      style={{ ...{ display: pathname === '/login' ? 'block' : 'none' }, ...bgImage }}
    >
      <form className="login-wrapper" onSubmit={onSubmit}>
        <div className="title mb-30">{t('login.login_your')}</div>
        <FormGroup
          onChange={(username) => setForm({ ...form, username })}
          value={form.username}
          label={t('login.usercode')}
          validation={validation.username}
          isSubmitted={isSubmitted}
          name="username"
        />
        <FormGroup
          onChange={(password) => setForm({ ...form, password })}
          value={form.password}
          label={t('login.password')}
          validation={validation.password}
          isSubmitted={isSubmitted}
          type="password"
          name="password"
        />
        <Button
          title={t('login.login')}
          className="btn btn-primary w100p btn-st"
          disabled={isLoading}
        />
      </form>
    </div>
  );
};

export default LoginPage;
